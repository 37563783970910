import { useCallback } from 'react';

import { FeatureFlagName, PermissionsScope } from '@sbiz/business';

import { useAuthUserContext } from '../contexts/AuthUserContext';
import { useFeatureFlagsContext } from '../contexts/FeatureFlagsContext';

export function useGetAuthorizedItems() {
  const { authUser } = useAuthUserContext();
  const featureFlags = useFeatureFlagsContext();

  return useCallback(
    <T extends { featureFlag?: FeatureFlagName; permissionsScope?: PermissionsScope }>(items: Readonly<T[]>) =>
      items.filter(({ featureFlag, permissionsScope }) => {
        const isDisabled = featureFlag && !featureFlags?.[featureFlag];
        const isForbidden = permissionsScope && !authUser.permissions[permissionsScope]?.read;

        return !(isDisabled || isForbidden);
      }),
    [authUser?.permissions, featureFlags],
  );
}
