export const API_CACHE_STORAGE_KEY_PREFIX = 'apiCache';

export const COOKIE_KEYS = { lang: 'config:lang' } as const;

export const LANG_COOKIE_OPTIONS = { maxAge: 60 * 60 * 24 * 30, path: '/' } as const;

export const STORAGE_KEYS = {
  apiUrl: 'config:apiUrl',
  authUser: 'auth:user',
  loginDialogs: 'login:dialogs',
  navigationState: 'config:navigationState',
  onLogin: 'isOnLogin',
  redirectAfterCompanySelection: 'companySelection:redirectPath',
  redirectAfterLogin: 'login:redirectPath',
  siteDataClearance: 'auth:siteDataCheckedAt',
  tokens: 'auth:tokens',
} as const;
