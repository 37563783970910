import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isJwtExpired } from '@sbiz/util-jwt';

import { Route, RouteOverride, RouteOverrideStorageItem, STORAGE_KEYS } from '../common';
import { COMPANY_INDEPENDANT_PATHS, isHomePathname, isRedirection, PAGES } from '../common/pages';
import { AuthUser, getCompanyId, isCompaniesAdministrator } from '../common/session';
import { useAuthUser } from './useAuthUser';
import { useGetAccessToken } from './useGetAccessToken';
import { useRoute } from './useRoute';
import { useStorageItem } from './useStorageItem';

export function useAuthRedirection(options?: { from?: string }) {
  const [routeOverride, setRouteOverride] = useState<RouteOverride | null>();

  const isRedirected = useRef(false);

  const { authUser, mutate } = useAuthUser();
  const isAuthUserReady = authUser !== undefined;
  const getAccessToken = useGetAccessToken();
  const { setValue: setIsOnLogin } = useStorageItem<boolean>(STORAGE_KEYS.onLogin);
  const { setValue: setCompanySelectionRedirection } = useStorageItem<RouteOverrideStorageItem>(
    STORAGE_KEYS.redirectAfterCompanySelection,
  );
  const { getValue: getLoginRedirection, setValue: setLoginRedirection } = useStorageItem<RouteOverrideStorageItem>(
    STORAGE_KEYS.redirectAfterLogin,
  );
  const [route, setRoute] = useRoute();

  const getRedirectRoute = useCallback(
    (authUser: AuthUser | null | undefined, { pathname, searchParams }: Route): RouteOverride | null => {
      if (authUser?.accessToken && !isJwtExpired(authUser.accessToken)) {
        const loginRedirectRoute = getLoginRedirection();

        if (loginRedirectRoute) {
          setLoginRedirection(undefined);

          return {
            ...loginRedirectRoute,
            searchParams: new URLSearchParams(loginRedirectRoute.searchParams ?? undefined),
          };
        }

        if (getCompanyId(authUser)) {
          return isHomePathname(pathname) ? { pathname: PAGES.company.path, searchParams: null } : null;
        }

        if (isCompaniesAdministrator(authUser)) {
          if (COMPANY_INDEPENDANT_PATHS.includes(pathname)) {
            return null;
          }

          setCompanySelectionRedirection({ pathname, searchParams: searchParams.toString() });

          return { pathname: PAGES.companySelection.path, searchParams: null };
        }
      }

      return { pathname: PAGES.logout.path, searchParams: null };
    },
    [getLoginRedirection, setCompanySelectionRedirection, setLoginRedirection],
  );

  const redirect = useCallback(async () => {
    if (authUser) {
      const accessToken = await getAccessToken();
      if (accessToken) {
        authUser.accessToken = accessToken;
      }
    }

    const override = getRedirectRoute(authUser, route);
    const routeOverride = override && isRedirection(route, override) ? override : null;

    setRouteOverride(routeOverride);

    if (routeOverride) {
      const { pathname, searchParams } = route;

      if (routeOverride?.pathname === PAGES.logout.path && pathname !== PAGES.login.path && !isHomePathname(pathname)) {
        setLoginRedirection({ pathname, searchParams: searchParams.toString() });
      }

      if (options?.from === PAGES.login.path) {
        setIsOnLogin(true);
      }

      setRoute(routeOverride);
    }
  }, [authUser, getAccessToken, getRedirectRoute, options?.from, route, setIsOnLogin, setLoginRedirection, setRoute]);

  useEffect(() => {
    if (isAuthUserReady && !isRedirected.current) {
      isRedirected.current = true;
      redirect();
    }
  }, [isAuthUserReady, redirect]);

  return useMemo(
    () => ({ authUser, isLoading: routeOverride === undefined, mutate, routeOverride }),
    [authUser, mutate, routeOverride],
  );
}
