import { useCallback, useEffect, useMemo, useState } from 'react';

import { UserInfo } from '@sbiz/business';
import { Json } from '@sbiz/util-mongodb';

import { useAuthApi } from '../common/api/hooks/useAuthApi';
import { useClearCache } from '../common/api/hooks/useClearCache';
import { AuthUser } from '../common/session';
import { useAuthUserStorageItem } from './useAuthUserStorageItem';
import { useTargetCompanyIdQueryParam } from './useTargetCompanyIdQueryParam';

export type AuthUserMutator = (user?: Partial<AuthUser>) => Promise<void>;

export function useAuthUser() {
  const { me } = useAuthApi();
  const { getValue: getAuthUser, setValue: setAuthUser, value: storedAuthUser } = useAuthUserStorageItem();
  const authUser = storedAuthUser ?? null;
  const clearCache = useClearCache();
  const [targetQueryParam, setTargetQueryParam] = useTargetCompanyIdQueryParam();

  const [targetCompanyIdOverride, setTargetCompanyIdOverride] = useState(() => {
    const targetQueryParamValue = targetQueryParam?.value;

    if (
      typeof storedAuthUser?._id === 'string' &&
      typeof targetQueryParamValue === 'string' &&
      targetQueryParamValue !== storedAuthUser.targetCompanyId
    ) {
      return targetQueryParamValue;
    }
  });
  const isTargetCompanyIdOverride = Boolean(targetCompanyIdOverride);

  const mutate = useCallback(
    async (values?: Partial<AuthUser>) => {
      if (authUser?._id) {
        let mutations = values;
        if (!mutations) {
          ({ data: mutations } = await me());
        }

        setAuthUser((currentUser) => {
          if (!currentUser) {
            return null;
          }

          return { ...currentUser, ...mutations };
        });
      }
    },
    [authUser?._id, me, setAuthUser],
  );

  const getUserOverrides = useCallback(
    async (
      user: AuthUser,
    ): Promise<Partial<Pick<AuthUser, 'companies' | 'companyId' | 'targetCompanyId'>> | undefined> => {
      if (targetCompanyIdOverride) {
        if (isCompanyAdmin(user, targetCompanyIdOverride)) {
          return { targetCompanyId: targetCompanyIdOverride };
        }

        const { data: freshUser } = await me();
        if (freshUser && isCompanyAdmin(freshUser, targetCompanyIdOverride)) {
          const { companies, companyId } = freshUser;
          return { companies, companyId, targetCompanyId: targetCompanyIdOverride };
        }
      }
    },
    [me, targetCompanyIdOverride],
  );

  const refreshUser = useCallback(async () => {
    if (authUser) {
      const overrides = await getUserOverrides(authUser);

      if (overrides) {
        setAuthUser((currentValue) => (currentValue ? { ...currentValue, ...overrides } : null));
        clearCache();
      }

      setTargetQueryParam(overrides?.targetCompanyId ?? authUser.targetCompanyId);
    }

    setTargetCompanyIdOverride(undefined);
  }, [authUser, clearCache, getUserOverrides, setAuthUser, setTargetQueryParam]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  return useMemo(
    () => ({ authUser: isTargetCompanyIdOverride ? undefined : authUser, getAuthUser, mutate }),
    [authUser, getAuthUser, isTargetCompanyIdOverride, mutate],
  );
}

function isCompanyAdmin(user: Json<UserInfo>, targetCompanyId: string) {
  return user.companies.some(({ _id: companyId }) => companyId === targetCompanyId);
}
