import { useMemo } from 'react';

import {
  ADMIN_PERMISSIONS_NAME,
  getAdminPermissionsScope,
  PermissionsAction,
  PermissionsScope,
  PermissionsValues,
} from '@sbiz/business';
import { isPlainObject } from '@sbiz/util-common';

import { useAuthUserContext } from '../contexts/AuthUserContext';

export function useIsAuthorized(scope: PermissionsScope | undefined, ...actions: PermissionsAction[]) {
  const {
    authUser: { permissions, permissionsName },
  } = useAuthUserContext();

  return useMemo(() => {
    if (!scope) {
      return true;
    }

    if (actions.length === 0) {
      actions.push('read');
    }

    if (isAuthorized(scope, actions, permissions)) {
      return true;
    }

    if (permissionsName === ADMIN_PERMISSIONS_NAME) {
      const adminScope = getAdminPermissionsScope(scope);

      if (adminScope) {
        return isAuthorized(adminScope, actions, permissions);
      }
    }

    return false;
  }, [actions, permissions, permissionsName, scope]);
}

function isAuthorized(scope: PermissionsScope, actions: PermissionsAction[], permissions: PermissionsValues) {
  const userActions = permissions[scope];
  return isPlainObject(userActions) && actions.every((action) => userActions[action]);
}
