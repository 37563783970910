import { createContext, ReactNode, useContext } from 'react';

import { FeatureFlags } from '@sbiz/business';

import { useFeatureFlags } from '../hooks/useFeatureFlags';

const FeatureFlagsContext = createContext<FeatureFlags | null>({});

export function FeatureFlagsContextProvider({ children }: { children: ReactNode }) {
  const { data: featureFlags } = useFeatureFlags();
  const value = featureFlags ?? null;

  return (
    <FeatureFlagsContext.Provider value={value}>{featureFlags !== undefined && children}</FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlagsContext() {
  return useContext(FeatureFlagsContext);
}
