import { useCallback, useMemo } from 'react';

import { PARAM_CACHE, QueryParam } from './useQueryParam';
import { useRoute } from './useRoute';

export function useQueryParams<T extends Readonly<string[]>>(names: T) {
  const [{ searchParams }, setRoute] = useRoute();

  const params = useMemo(
    () =>
      names.map((name) => {
        const queryParam = searchParams?.get(name);

        if (typeof queryParam !== 'string') {
          return null;
        }

        const cachedParam = PARAM_CACHE.get(name);
        const cachedValue = cachedParam?.value;

        if (!cachedValue || String(cachedValue) !== String(queryParam)) {
          PARAM_CACHE.set(name, { name, value: queryParam });
        }

        return PARAM_CACHE.get(name) as QueryParam;
      }),
    [names, searchParams],
  );

  const setParams = useCallback(
    (newValues: { [K in T[number]]?: string | null }) => {
      const urlSearchParams = new URLSearchParams(searchParams ?? undefined);

      let isQueryChange = false;
      for (const [key, newValue] of Object.entries(newValues) as [T[number], string | null][]) {
        const index = names.findIndex((name) => name === key);

        if (index > -1) {
          const param = params[index];
          const isSameValue = (newValue === null && !param) || String(newValue) === String(param?.value);

          if (!isSameValue) {
            const name = names[index];

            if (newValue === null) {
              urlSearchParams.delete(name);
            } else {
              urlSearchParams.set(name, newValue);
            }

            isQueryChange = true;
          }
        }
      }

      if (isQueryChange) {
        setRoute({ searchParams: urlSearchParams });
      }
    },
    [names, params, searchParams, setRoute],
  );

  return [params, setParams] as const;
}
